import Card from "../Card";
import styles from "./Sections.module.css";

function Sections({ selectedSection, onSelect }) {
  const menu = [
    {
      name: "BALANÇAS",
    },
    {
      name: "SISTEMA DE GESTÃO",
    },
    {
      name: "SOLUÇÕES TEF",
    },
    {
      name: "CONTROLE DE PONTO",
    },
    {
      name: "SERVIÇOS",
    },
    {
      name: "EQUIPAMENTOS",
    },
  ];

  return (
    <Card customID={"Sections"} customClass={"sections"}>
      <div id={styles.menu}>
        {menu.map((menus, index) => (
          <div
            className={`${styles.menuSelector} ${
              selectedSection === index && styles.activated
            }`}
            onClick={() => onSelect(index)}
          >
            {menus.name}
          </div>
        ))}
      </div>
      {selectedSection === 0 && (
        <>
          <div className={styles.about}>
            <h3>Balanças de Alta Qualidade</h3>
            <div className={styles.text}>
              Na OnSite, entendemos a importância de precisão e eficiência em
              seu negócio. É por isso que oferecemos uma seleção premium de
              balanças das renomadas marcas Toledo, Ramuza e UPX. Se você está
              procurando confiabilidade, durabilidade e tecnologia de ponta,
              você está no lugar certo.
            </div>
            <h3>Balança Toledo Prix 6 </h3>
            <div className={styles.text}>
              A Toledo Prix 6 é a nossa estrela em balanças comerciais.
              Projetada para atender às demandas dos ambientes mais exigentes, a
              Prix 6 não só garante precisão em cada pesagem, mas também se
              destaca pela sua etiqueta contínua, proporcionando uma grande
              economia de etiquetas. Ideal para varejos e mercados, esta balança
              combina eficiência e economia, ajudando a reduzir custos e
              otimizar o seu processo de etiquetagem.
            </div>
          </div>
          <div className={styles.about_2}>
            <h3>Marcas que trabalhamos</h3>
            <ul>
              <li>
                <b>Toledo: </b>Sinônimo de inovação e confiabilidade, a Toledo
                oferece balanças que atendem às demandas comerciais com
                eficiência e tecnologia avançada.
              </li>
              <li>
                <b>Ramuza: </b>Reconhecida por sua robustez, a Ramuza
                proporciona balanças duráveis e de alto desempenho para diversas
                aplicações comerciais.
              </li>
              <li>
                <b>UPX: </b>Combinando tecnologia de ponta e facilidade de uso,
                a UPX entrega soluções de pesagem modernas e eficazes.
              </li>
            </ul>
          </div>
        </>
      )}

      {selectedSection === 1 && (
        <>
          <div className={styles.about}>
            <h3>Soluções Avançadas em Sistemas de Gestão para Seu Negócio</h3>
            <div className={styles.text}>
              Na OnSite, oferecemos soluções de sistemas de gestão que
              transformam a maneira como você gerencia suas vendas e operações
              comerciais. Trabalhamos com duas das melhores opções do mercado:
              Master Key e Logicbox.
            </div>
            <h3>Por que Escolher Nossos Sistemas de Gestão?</h3>
            <div className={styles.text}>
              <li>
                <b>Eficiência e Versatilidade: </b>Com o Master Key, você obtém
                um sistema integrado que se adapta às necessidades específicas
                do seu negócio, desde a gestão de pedidos em restaurantes até a
                integração com suas notas de compra.
              </li>
              <li>
                <b>Tecnologia Avançada: </b>O Logicbox oferece uma solução
                completa e robusta, ideal para mercados que precisam de um
                sistema eficiente e confiável para gerenciar suas operações
                diárias.
              </li>
              <li>
                <b>Facilidade de Uso: </b>
                Nossos sistemas são projetados para serem intuitivos, ajudando a
                otimizar suas operações e melhorar a eficiência do seu negócio.
              </li>
            </div>
          </div>
          <div className={styles.about_2}>
            <ul>
              <li>
                O <b>Master Key</b> é ideal para negócios que buscam eficiência
                e versatilidade. Este sistema não só oferece um módulo de
                restaurante completo, facilitando a gestão de pedidos e mesas,
                como também permite pedidos externos através de um aplicativo
                móvel, proporcionando comodidade para seus clientes. Além disso,
                o Master Key inclui a funcionalidade de importação de notas de
                compra, integrando-se facilmente com sua contabilidade e
                processos de compra.
              </li>
              <li>
                <b>Logicbox</b> é uma solução extremamente completa, projetada
                especialmente para mercados e grandes varejos. Com uma gama
                abrangente de funcionalidades, o Logicbox atende todas as suas
                necessidades de gestão de vendas e inventário, oferecendo um
                sistema robusto e eficiente para operações de grande escala.
              </li>
            </ul>
          </div>
        </>
      )}

      {selectedSection === 2 && (
        <>
          <div className={styles.about}>
            <h3>Soluções de Integração TEF</h3>
            <div className={styles.text}>
              As soluções TEF da Onsite Tecnologia são projetadas para otimizar
              a gestão de pagamentos em qualquer empresa, oferecendo
              flexibilidade, eficiência e segurança. Com compatibilidade total
              com a tecnologia SiTEF, essas soluções podem ser integradas a
              qualquer sistema que suporte essa tecnologia, tornando-as ideais
              para diversos tipos de negócios.
            </div>
            <h3>SiTEF IP</h3>
            <div className={styles.text}>
              A opção <b>SITEF IP</b>, por exemplo, é perfeita para empresas com
              múltiplos pontos de venda (PDVs) e que trabalham com diversas
              máquinas de cartão. Ela permite integração com as principais
              adquirentes do mercado, como <b>Cielo, Stone, Vero</b> e outras.
              Com essa solução, é possível fazer o roteamento inteligente das
              bandeiras, escolhendo qual adquirente processará cada transação,
              garantindo assim taxas mais vantajosas e maior controle sobre os
              pagamentos.
            </div>
          </div>
          <div className={styles.about_2}>
            <h3>Smart TEF (Master Key)</h3>
            <div className={styles.text}>
              Além disso, a solução <b>Smart TEF</b> oferece uma integração ágil
              com o sistema Master Key, sendo ideal para quem utiliza até dois
              adquirentes e poucos PDVs. Essa opção também funciona com máquinas
              de cartão do tipo SMART, oferecendo uma alternativa eficiente e
              econômica para pequenos negócios.
            </div>
            <div className={styles.text}>
              Com essas soluções, sua empresa tem à disposição um sistema
              robusto e confiável que se adapta às suas necessidades, seja para
              um ponto de venda ou múltiplos PDVs, assegurando um processo de
              pagamento prático, seguro e customizado.
            </div>
          </div>
        </>
      )}

      {selectedSection === 3 && (
        <>
          <div className={styles.about}>
            <h3>Gestão de Ponto</h3>
            <div className={styles.text}>
              Na OnSite Tecnologia, oferecemos soluções completas para o
              controle de ponto, garantindo praticidade e eficiência no
              gerenciamento de horas trabalhadas e presença dos colaboradores.
              Entre nossas principais ofertas, destacam-se o
              <b> Secullum Ponto Web</b> e os equipamentos de alta tecnologia da
              <b> Control ID</b>.
            </div>
            <div className={styles.text}>
              O <b>Secullum Ponto Web</b> é uma plataforma totalmente online,
              que facilita o registro de ponto por meio de várias
              funcionalidades, como o uso de geolocalização, reconhecimento
              facial e assinaturas eletrônicas. Seu sistema de backup automático
              em nuvem garante a segurança das informações, enquanto sua
              compatibilidade com dispositivos móveis oferece flexibilidade aos
              colaboradores, que podem registrar seu ponto via aplicativo,
              inclusive em locais sem conexão com a internet. Além disso, a
              plataforma permite a emissão de relatórios personalizados e
              oferece integração direta com diversos sistemas de folha de
              pagamento, otimizando o trabalho do setor de RH
            </div>
          </div>
          <div className={styles.about_2}>
            <div className={styles.text}>
              Para empresas que buscam uma solução de hardware eficiente e
              acessível, os dispositivos <b>Control ID</b>, como o
              <b> IDClass</b>, oferecem tecnologia confiável no controle de
              ponto. Esses equipamentos são totalmente compatíveis com o
              Secullum Ponto Web, proporcionando uma integração fluida e segura.
              O IDClass permite o registro de ponto com precisão através de
              leitura biométrica, garantindo um controle de acesso eficiente e
              adequado para diferentes ambientes de trabalho​
            </div>
            <div className={styles.text}>
              Essa combinação entre o software <b>Secullum Ponto Web</b> e o
              hardware <b>IDClass</b> oferece uma solução completa, garantindo
              que o registro e o gerenciamento de ponto sejam realizados de
              forma simples, rápida e segura, ideal para empresas que buscam
              modernizar seus processos e otimizar a gestão de recursos humanos.
            </div>
          </div>
        </>
      )}

      {selectedSection === 4 && (
        <>
          <div className={styles.about}>
            <h3>Serviços</h3>
            <div className={styles.text}>
              Na OnSite Tecnologia, oferecemos uma linha completa de serviços
              especializados para manter seus equipamentos funcionando de forma
              eficiente e contínua. Nossas soluções incluem:
            </div>
            <ul>
              <li>
                <b>Manutenção de computadores: </b>Garantimos o perfeito
                funcionamento de computadores empresariais, realizando
                diagnósticos, reparos e otimizações de hardware e software.
                Nosso serviço de manutenção preventiva e corretiva minimiza
                interrupções e garante que seus sistemas estejam sempre
                atualizados e seguros.
              </li>
              <li>
                <b>Manutenção de balanças: </b>Com nossa expertise, realizamos
                calibração e conserto de balanças comerciais e industriais,
                assegurando que os equipamentos funcionem com precisão e estejam
                em conformidade com as regulamentações vigentes. Nossos técnicos
                especializados oferecem suporte rápido para que seu negócio não
                pare.
              </li>
            </ul>
          </div>
          <div className={styles.about_2}>
            <ul>
              <li>
                <b>Conserto de impressoras térmicas e de etiqueta: </b>Prestamos
                serviços de reparo em impressoras térmicas e de etiquetas,
                essenciais para operações de logística, comércio e indústria.
                Seja para consertar falhas mecânicas, corrigir problemas de
                impressão ou realizar manutenções preventivas, nossos técnicos
                estão prontos para garantir que suas impressoras funcionem com a
                máxima eficiência.
              </li>
              <li>
                <b>Conserto de nobreaks e troca de baterias: </b>Além de
                proteger seus sistemas contra falhas de energia, também
                oferecemos o serviço de conserto e substituição de baterias de
                nobreaks, prolongando a durabilidade e garantindo o
                funcionamento ininterrupto dos seus equipamentos.
              </li>
            </ul>
          </div>
        </>
      )}

      {selectedSection === 5 && (
        <>
          <div className={styles.about}>
            <h3>Equipamentos e Suprimentos</h3>
            <div className={styles.text}>
              Na OnSite Tecnologia, oferecemos uma ampla gama de equipamentos de
              alta qualidade para atender às diversas necessidades do seu
              negócio:
              <ul>
                <li>
                  <b>Computadores:</b> Equipamentos configurados para atender
                  desde demandas básicas até aplicações mais robustas,
                  garantindo desempenho e confiabilidade para todas as áreas da
                  sua empresa, desde o escritório até a produção.
                </li>
                <li>
                  <b>Impressoras: </b>Disponibilizamos impressoras térmicas, de
                  etiquetas e à laser, ideais para otimizar processos de
                  impressão em comércios, indústrias e escritórios. Nossas
                  impressoras térmicas e de etiquetas são perfeitas para
                  impressão de cupons fiscais, etiquetas de produto e logística.
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.about_2}>
            <ul>
              <li>
                <b>Nobreaks: </b>Proteja seus equipamentos contra quedas de
                energia e oscilações com nossos nobreaks de alta performance,
                garantindo que seus sistemas continuem funcionando sem
                interrupções, mesmo em casos de falha elétrica.
              </li>
            </ul>
            <div className={styles.text}>
              Além disso, oferecemos suprimentos essenciais como
              <b> rolos de papel térmico</b> para impressoras de cupom e
              <b> rolos de etiqueta</b>, assegurando que suas operações de
              impressão nunca parem por falta de material.
            </div>
            <div className={styles.text}>
              Com nossa linha de equipamentos, sua empresa terá o suporte
              necessário para operar de forma eficiente, segura e com alta
              produtividade.
            </div>
          </div>
        </>
      )}
    </Card>
  );
}

export default Sections;
