import styles from "./Footer.module.css";
import useIsMobile from "../IsMobile";

function Footer() {
  const isMobile = useIsMobile();

  return (
    <div
      className={`${styles.Footer} ${
        isMobile ? styles.mobile : styles.desktop
      }`}
    >
      <div className={styles.content}>
        <div className={styles.address}>
          Rua João Sarmento, 601 - Centro
          <br />
          Osório - RS
          <br />
        </div>
        <div className={styles.phone}>
          Telefone
          <br />
          3663-5264
        </div>
        <div className={styles.email}>
          atendimento@onsitetecnologia.com.br
          <br />
          suporte@onsitetecnologia.com.br
        </div>
        {!isMobile && (
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13836.693428192837!2d-50.2643213!3d-29.8881051!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951865fd98a037c7%3A0x506509d6c7a38407!2sOn%20Site%20Tecnologia!5e0!3m2!1spt-BR!2sbr!4v1722345892942!5m2!1spt-BR!2sbr"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        )}
      </div>
    </div>
  );
}

export default Footer;
