import styles from "./Card.module.css";
import useIsMobile from "./IsMobile";

function Card(props) {
  const isMobile = useIsMobile();

  return (
    <section id={props.customID}>
      <div
        className={`${styles.card} 
        ${isMobile ? styles.mobile : styles.desktop}
        ${props.customClass && styles.sections}`}
      >
        {props.children}
      </div>
    </section>
  );
}

export default Card;
