import styles from "./SectionCalls.module.css";

import { GiKitchenScale } from "react-icons/gi";
import {
  HiComputerDesktop,
  HiFingerPrint,
  HiMiniCreditCard,
  HiMiniShoppingCart,
  HiMiniWrenchScrewdriver,
} from "react-icons/hi2";

const sections = [
  {
    icon: <GiKitchenScale size={`6rem`} />,
    name: `BALANÇAS`,
  },
  {
    icon: <HiMiniShoppingCart size={`6rem`} />,
    name: `SISTEMAS DE GESTÃO`,
  },
  {
    icon: <HiMiniCreditCard size={`6rem`} />,
    name: `SOLUÇÕES TEF`,
  },
  {
    icon: <HiFingerPrint size={`6rem`} />,
    name: `CONTROLE DE PONTO`,
  },
  {
    icon: <HiMiniWrenchScrewdriver size={`6rem`} />,
    name: `SERVIÇOS`,
  },
  {
    icon: <HiComputerDesktop size={`6rem`} />,
    name: `EQUIPAMENTOS`,
  },
];

function SectionCalls({ onSelect }) {
  function scrollToElement(event, id, index, offset = 150) {
    event.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      // Calcula a posição do elemento alvo
      const rect = element.getBoundingClientRect();
      const elementTop = rect.top + window.scrollY;

      // Ajusta a rolagem para compensar o offset
      window.scrollTo({
        top: elementTop - offset,
        behavior: "smooth",
      });

      onSelect(index);
    }
  }

  return (
    <div className={styles.sectionCardWrapper}>
      {sections.map((section, index) => (
        <div
          className={styles.sectionCard}
          onClick={(e) => scrollToElement(e, "Sections", index)}
        >
          <div className={styles.iconWrapper}>
            <div className={styles.iconStyleWrapper}>{section.icon}</div>
          </div>

          <h3>{section.name}</h3>
        </div>
      ))}
    </div>
  );
}

export default SectionCalls;
