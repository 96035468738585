import { useState } from "react";
import "./App.css";

//COMPONENTS
import Header from "./components/Header/Header";
import SectionCalls from "./components/SectionCalls/SectionCalls";
import Downloads from "./components/Downloads/Downloads";
import Contato from "./components/Contato/Contato";
import Sections from "./components/Sections/Sections";
import About from "./components/About";
import Footer from "./components/Footer/Footer";

// IMAGENS
// import balanca from "./img/CardViewer/balanca.png";
// import office from "./img/CardViewer/logo.png";
// import oryon from "./img/CardViewer/logo_oryon.png";
// import secullum from "./img/CardViewer/Secullum.png";
// import controlid from "./img/CardViewer/idclass.png";
// import windows from "./img/CardViewer/windows.png";
// import PC from "./img/CardViewer/NTC Home - Frontal_Lateral.png";
// import el250 from "./img/CardViewer/el250.png";

// import Slideshow from "./components/Slideshow";
// import CardViewer from "./components/CardViewer/CardViewer";

// Dados para o mapeamento
// const cardViewers = [
//   {
//     customID: "Balancas",
//     nome: "BALANÇAS",
//     image: balanca,
//   },
//   {
//     customID: "SistemasGestao",
//     nome: "SISTEMAS DE GESTÃO",
//     image: office,
//     image2: oryon,
//   },
//   {
//     customID: "ControlePonto",
//     nome: "CONTROLE DE PONTO",
//     image: secullum,
//     image2: controlid,
//   },
//   { customID: "Servicos", nome: "SERVIÇOS", image: windows },
//   { customID: "Equipamentos", nome: "EQUIPAMENTOS", image: PC, image2: el250 },
// ];

function App() {
  const [selectedSection, setSelectedSection] = useState(0);

  const handleSelect = (section) => {
    setSelectedSection(section);
  };

  return (
    <div className="App">
      <Header />
      {/* <Slideshow /> */}

      <SectionCalls onSelect={handleSelect} />

      {/* <div className="view">
        {cardViewers.map((card, index) => (
          <CardViewer
            key={index}
            customID={card.customID}
            nome={card.nome}
            image={card.image}
            {...(card.image2 ? { image2: card.image2 } : {})}
          />
        ))}
      </div> */}

      <About />
      <Downloads />
      <Sections selectedSection={selectedSection} onSelect={handleSelect} />
      <Contato />
      <Footer />
    </div>
  );
}

export default App;
