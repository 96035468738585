import Card from "./Card";
import styles from "./About.module.css";

function About() {
  return (
    <Card customID="About">
      <h2>Sobre nós</h2>
      <div className={styles.content}>
        <p>
          Fundada em 2002, somos uma empresa pioneira no desenvolvimento de
          soluções completas para automação comercial. Estamos estabelecidos em
          Osório, no coração do Litoral Norte do Rio Grande do Sul, nos
          destacamos por oferecer soluções integradas que abrangem desde a
          frente de caixa até a gestão empresarial. Nossa filosofia é baseada em
          proximidade e confiança.
        </p>
        <p>
          Mantemos um compromisso constante com nossos clientes, proporcionando
          não apenas produtos e serviços, mas uma parceria de longo prazo. Com o
          respaldo de parcerias estratégicas com grandes marcas de automação
          comercial, oferecemos uma plataforma única para automatizar seu
          negócio de maneira eficiente e econômica.
        </p>
      </div>
    </Card>
  );
}

export default About;
