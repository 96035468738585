import Card from "../Card";
import useIsMobile from "../IsMobile";
import { Buttons } from "./Buttons";
import "./Contato.css";

function Contato() {
  const isMobile = useIsMobile();

  return (
    <Card customID="Contatos">
      <h2>Contatos</h2>
      <div className={`content ${isMobile ? `mobile` : `desktop`}`}>
        <Buttons />
      </div>
    </Card>
  );
}

export default Contato;
