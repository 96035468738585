import Card from "../Card";
import styles from "./Downloads.module.css";

import AnydeskLogo from "../icons/anydesk-logo.png";
import TeamviewerLogo from "../icons/teamviewer.png";

import { useCollapse } from "react-collapsed";
import { message } from "react-message-popup";
import useIsMobile from "../IsMobile";

function Downloads() {
  const isMobile = useIsMobile();

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  function CopyLink(url) {
    try {
      navigator.clipboard.writeText(url);
      message.success("Link FTP Copiado!", 3000);
    } catch (err) {
      const input = document.createElement("input");
      input.value = url;
      document.body.appendChild(input);
      input.select();
      try {
        const successful = document.execCommand("copy");
        if (successful) {
          message.success("Link FTP Copiado", 3000); // Mensagem de sucesso
        } else {
          throw new Error("Não foi possível copiar o texto"); // Força o tratamento de erro
        }
      } catch (err) {
        message.error("Falha ao copiar o link"); // Mensagem de erro
        console.error("Erro ao copiar para a área de transferência: ", err);
      } finally {
        document.body.removeChild(input); // Remove o input temporário
      }
    }
  }

  function OpenLink(e, url) {
    if (window.open(url, "_blank")) {
      message.success(
        "Download de " + e.target.innerText + " realizado!",
        3000
      );
    }
  }

  return (
    <Card customID="Downloads">
      <h2>Downloads</h2>
      <div className={styles.content}>
        Abaixo você pode encontrar links para download das ferramentas que
        utilizamos para nosso suporte remoto.
        <div
          className={`${styles.icons} ${
            isMobile ? styles.mobile : styles.desktop
          }`}
        >
          <div
            className={styles.AccessWrapper}
            onClick={() =>
              window.open("http://anydesk.com/pt/downloads/", "_blank")
            }
          >
            <img id="AnydeskLogo" src={AnydeskLogo} />
            <label htmlFor="#AnydeskLogo">
              <h4>Anydesk</h4>
            </label>
          </div>
          <div
            className={styles.AccessWrapper}
            onClick={() =>
              window.open(
                "https://get.teamviewer.com/onsitequicksuporte",
                "_blank"
              )
            }
          >
            <img id="TeamviewerLogo" src={TeamviewerLogo} />
            <label htmlFor="#TeamviewerLogo">
              <h4>Teamviewer</h4>
            </label>
          </div>
        </div>
      </div>
      <button {...getToggleProps()}>{isExpanded ? "⯅" : "⯆"}</button>
      <div className={styles.auxilioSuporte} {...getCollapseProps()}>
        <a
          onClick={(e) =>
            OpenLink(
              e,
              "https://files.officesystem.com.br/instaladores/SetupMKNFe.exe"
            )
          }
        >
          Master Key + NFe
        </a>
        <a
          onClick={(e) => {
            OpenLink(
              e,
              "https://files.officesystem.com.br/atualizacoes/Updater.zip"
            );
          }}
        >
          Updater
        </a>
        <a
          onClick={(e) => {
            OpenLink(
              e,
              "https://files.officesystem.com.br/atualizacoes/Etiqueta.zip"
            );
          }}
        >
          Etiquetas
        </a>
        <a onClick={() => CopyLink("ftp://177.73.3.40:2120")}>FTP</a>
      </div>
    </Card>
  );
}

export default Downloads;
