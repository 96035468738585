import React from "react";
import WhatsAppText from "../whatsapp";
import styles from "./Buttons.module.css";
import useIsMobile from "../IsMobile";

function Buttons() {
  const isMobile = useIsMobile();

  return (
    <>
      <div
        className={`${styles.buttons} ${
          isMobile ? styles.mobile : styles.desktop
        }`}
      >
        <a href={WhatsAppText("Olá! Gostaria de falar com o setor de Vendas")}>
          <button>Vendas</button>
        </a>
        <a href={WhatsAppText("Olá! Gostaria de falar com o Financeiro")}>
          <button>Financeiro</button>
        </a>
        <a href={WhatsAppText("Olá! Gostaria de falar com o Suporte")}>
          <button>Suporte</button>
        </a>
        <a
          href={WhatsAppText(
            "Olá! Gostaria de falar com o Consultor de Automação"
          )}
        >
          <button>Consultor de Automação Comercial</button>
        </a>
      </div>
    </>
  );
}

function DownBtn() {
  return (
    <button className="downBtn">
      <a href="#divContainer">&gt;</a>
    </button>
  );
}

export { Buttons, DownBtn };
